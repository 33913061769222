import { createContext, ReactChild, useContext } from 'react';
import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";
import { Database, getDatabase } from "firebase/database";
import { FirebaseStorage, getStorage } from "firebase/storage";

interface FirebaseContext {
  app: FirebaseApp;
  db: Database;
  storage: FirebaseStorage;
}

const FIREBASE_CONTEXT = createContext<FirebaseContext | null>(null);

export const useFirebase = () => {
  return useContext(FIREBASE_CONTEXT);
}

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBYIspyuJdhNa5Va8nuyMWHTjEj46t3A0w",
  authDomain: "scavenger-social.firebaseapp.com",
  databaseURL: "https://scavenger-social-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scavenger-social",
  storageBucket: "scavenger-social.appspot.com",
  messagingSenderId: "487303946978",
  appId: "1:487303946978:web:e74bcd71a99f4d45bc74bb"
};

const fb = initializeApp(firebaseConfig);
const database = getDatabase(fb);
const storage = getStorage(fb);

export function FirebaseProvider({children}:{children: ReactChild | ReactChild[]}) {
  return (
    <FIREBASE_CONTEXT.Provider
      value={{
        app: fb,
        db: database,
        storage: storage,
      }}
    >
      {children}
    </FIREBASE_CONTEXT.Provider>
  );
}
