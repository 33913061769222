import { useRef, useState } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Button,
  Typography,
} from '@mui/material';
import { Map, GeolocateControl } from 'react-map-gl';
import { LocationTask, LocationDescription } from '../lib/types';
import { CommonDetailsProps } from './Task';
import Hint from './Hint';

function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d * 1000; // meters
}

interface VisitDetailsProps extends CommonDetailsProps {
  task: LocationTask
}

export function VisitDetails({task, buyHint, completeTask, hintBought}: VisitDetailsProps) {
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  const [coords, setCoords] = useState<GeolocationCoordinates>({
    latitude: 80,
    longitude:-1,
    accuracy: 1000,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });
  const inaccurate = coords.accuracy > 100;
  if (typeof task.description === 'string') {
    return (
      <AccordionDetails>
        <Typography>Ooops.. There was an Error</Typography>
      </AccordionDetails>
    );
  }

  const question = task.description as LocationDescription;

  const dist = distance(
    question.target.lat, question.target.lng,
    coords.latitude, coords.longitude
  );

  // TODO maybe a bit lenient
  const correct = dist - (coords.accuracy / 2) < 100;

  return (
    <>
      <AccordionDetails>
        <Typography sx={{mb: 1}} variant="body1">
          {question.question}
        </Typography>
        <Map
          style={{width: "100%",  height: "300px"}}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          latitude={coords.latitude}
          longitude={coords.longitude}
          zoom={16}
          mapStyle="mapbox://styles/mapbox/light-v11"
          onLoad={() => geoControlRef.current?.trigger()}
        >
          <GeolocateControl
            ref={geoControlRef}
            style={{right: 10, bottom: 35}}
            positionOptions={{enableHighAccuracy: true}}
            trackUserLocation={true}
            showUserLocation={true}
            showAccuracyCircle={true}
            onGeolocate={({coords: newCoords}: {coords: GeolocationCoordinates}) => { console.log("hmm"); setCoords(newCoords)}}
          />
        </Map>
        {inaccurate && (
          <Typography
            sx={{color: "secondary.main", mt: 2}}
            component="p"
            textAlign="center"
            variant="caption"
          >
            Location is too inaccurate ({coords.accuracy}m).
            Try again in a few moments.
          </Typography>
        )}
      </AccordionDetails>
      <AccordionActions>
        <Hint
          bought={hintBought}
          buyHint={() => buyHint(task.id)}
          hint={task.hint}
        />
        <Button
          disabled={inaccurate}
          onClick={() => completeTask({
            task_id: task.id,
            correct: correct,
          })}
        >
          Submit current location
        </Button>
      </AccordionActions>
    </>
  );
}
