import { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HuntContext, useHuntUnauthed } from '../../lib/HuntProvider';

interface MenuProps {
  hunt: HuntContext;
  onGoBack?: () => void;
}

function HuntMenu({ hunt }: MenuProps) {
  const [inp, setInp] = useState<string>(hunt.huntId?? "");
  const isError = hunt.invalidHuntId && hunt.huntId === inp;

  return (
    <>
      <TextField
        error={isError}
        fullWidth
        label="Hunt ID"
        id="hunt-id"
        helperText={isError
          ? "Invalid hunt ID"
          : ""
        }
        onChange={(e) => {
          setInp(e.target.value);
        }}
        value={inp}
      />
      <LoadingButton
        disabled={inp === ""}
        loading={hunt.huntLoading}
        sx={{alignSelf: 'end'}}
        onClick={() => hunt.setHunt(inp)}
      >
        Next
      </LoadingButton>
    </>
  );
}

function NewTeamMenu({ hunt, onGoBack }: MenuProps) {
  const [inp, setInp] = useState<string>("");
  const [cringe, setCringe] = useState(false);

  return (
    <>
      <TextField
        fullWidth
        label="Team Name"
        id="team-name"
        onChange={(e) => {
          setInp(e.target.value);
          setCringe(false);
        }}
        value={inp}
      />
      { cringe && (
        <Typography sx={{color: "secondary.main"}} variant="caption">
          That's kinda cringe...
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'stretch',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{alignSelf: 'start'}}
          onClick={onGoBack}
          color="secondary"
        >
          Back
        </Button>
        <Button
          disabled={inp === "" || cringe}
          sx={{alignSelf: 'end'}}
          onClick={() => {
            if (inp.toUpperCase().match(/DROP TABLE/))
              setCringe(true);
            else
              hunt.createTeam(inp);
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
}


function TeamLoginMenu({ hunt, onGoBack }: MenuProps) {
  const [inp, setInp] = useState<string>("");
  const [loginError, setLoginError] = useState(false);

  return (
    <>
      <TextField
        error={loginError}
        fullWidth
        label="Login code"
        helperText={loginError
          ? "Invalid login code"
          : ""
        }
        id="team-name"
        onChange={(e) => setInp(e.target.value)}
        value={inp}
      />
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'stretch',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{alignSelf: 'start'}}
          onClick={onGoBack}
          color="secondary"
        >
          Back
        </Button>
        <Button
          disabled={inp === ""}
          sx={{alignSelf: 'end'}}
          onClick={() => setLoginError(!hunt.teamLogin(inp))}
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function TeamMenu({ hunt }: MenuProps) {
  const [route, setRoute] = useState<"Select"|"Create"|"Join">("Select");

  if (route === "Create") {
    return <NewTeamMenu hunt={hunt} onGoBack={() => setRoute("Select")} />;
  }

  if (route === "Join") {
    return <TeamLoginMenu hunt={hunt} onGoBack={() => setRoute("Select")} />;
  }

  return (
    <>
      <Button
        fullWidth
        onClick={() => setRoute("Create")}
        variant="outlined"
      >
        Create new team
      </Button>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setRoute("Join")}
        color="secondary"
      >
        Login to existing team
      </Button>
    </>
  );
}

export default function HuntHome() {
  const hunt = useHuntUnauthed();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 2,
      }}
    >
      <Typography variant="h4" textAlign="center">
        { hunt.huntDetails
          ? `Join ${hunt.huntDetails.name}'s hunt`
          : "Join a hunt"
        }
      </Typography>
      { hunt.huntId === undefined || hunt.invalidHuntId || hunt.huntLoading
        ?  <HuntMenu hunt={hunt} />
        : hunt.teamId === undefined
          && <TeamMenu hunt={hunt} />
      }
    </Box>
  );
}
