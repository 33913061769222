import { Paper, Typography } from '@mui/material';
import { useHuntAuthed } from '../../lib/HuntProvider';
import { useTeam } from '../../lib/teams';

export default function TeamDeatilsPage() {
  const { teamId } = useHuntAuthed();
  const [team, loading, error] = useTeam(teamId!);

  if (error) {
    console.error(error);
    return (
      <Typography variant="body1">
        There was an error
      </Typography>
    );
  }

  return (
    <Paper sx={{mt: 4, py: 2, px: 4}}>
      <Typography variant="h4">Team details</Typography>
      {loading
        ? <Typography>Loading...</Typography>
        : (
          <>
            <Typography>Name: {team!.name}</Typography>
            <Typography>Team code: {team!.code}</Typography>
          </>
      )}
    </Paper>
  );
}
