import { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { useHunt } from '../../lib/HuntProvider';
// import QuestionsEdit from './questions';
import Approve from './approve';

export function OrgerniserHome() {
  const {huntId, teamId, logout} = useHunt();

  useEffect(() => {
    if (huntId || teamId)
      logout();
  }, [huntId, teamId, logout]);

  return (
    <>
      <Typography variant="h4" sx={{mt: 2}}>Welcome organiser</Typography>
      <Link to="./questions">Edit Questions</Link>
      <br />
      <Link to="./approve">Approve submissions</Link>
    </>
  );
}

function Login({onLogin}: {onLogin: () => void}) {
  const [inp, setInp] = useState("");
  return (
    <>
      <Typography variant="h4" sx={{mt: 2}}>Login</Typography>
      <TextField
        fullWidth
        label="password"
        type="password"
        value={inp}
        onChange={(e) => setInp(e.target.value)}
        sx={{mt: 2}}
      />
      <Button
        disabled={inp === ""}
        onClick={() => {
          if (inp === "verynice")
            onLogin();
        }}
        sx={{display: 'flex', mt: 1, ml: 'auto'}}
      >
        Login
      </Button>
    </>
  );
}

const devmode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function OrgerniserPages() {
  const [authed, setAuthed] = useState(devmode);

  return (
    <Routes>
      { authed
        ? (
          <>
            <Route path="" element={<OrgerniserHome />} />
            { /* <Route path="questions" element={<QuestionsEdit />} /> */ }
            <Route path="approve" element={<Approve />} />
          </>
        ) : (
          <Route path="" element={<Login onLogin={() => setAuthed(true)}/>} />
        )
      }
    </Routes>
  );
}
