import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material';
import { ref, update } from 'firebase/database';
import { ref as sref } from 'firebase/storage';
import { useFirebase } from '../../lib/firebase';
import { useListVals } from 'react-firebase-hooks/database';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { Task, CompletedTask } from '../../lib/types';

interface Team {
  id: string,
  code: string,
  name: string,
  completedTasks: {
    [key: string]: CompletedTask,
  }
}

interface CTRef extends CompletedTask{
  teamId: string,
  compltedTaskId: string,
  asset_url?: string,
}

function DBImage({url}: {url: string}) {
  const { storage } = useFirebase()!;
  const [durl, loading] = useDownloadURL(sref(storage, url));

  if (loading) return <p>Loading</p>;

  return (
    <img style={{maxWidth: "100%", maxHeight: "300px"}}alt="" src={durl} />
  );
}

function TaskCardContent({task, submission, team}: {task: Task, submission: CTRef, team: Team}) {
  return (
    <CardContent>
      <Typography variant="h5">{task.title ?? "ERROR"}</Typography>
      {task.type !== "question" && task.type !== "visit"
        ?  <Typography>{task.description}</Typography>
        : <></>
      }
      <Typography variant="h5">Answer:</Typography>
      { submission.answer && <Typography>{submission.answer}</Typography>}
      { submission.asset_url && <DBImage url={submission.asset_url} />}
      <Typography>Correct: {submission.correct.toString()}</Typography>
      <Typography>Team name: {team.name}</Typography>
    </CardContent>
  );
}

export default function Approve() {
  const hunt_id = "css2023"
  const { db } = useFirebase()!;
  const [tasks, tloading, terror] = useListVals<Task>( ref(db, `/hunt/${hunt_id}/task`), { keyField: "id"});
  const [teams, loading, error] = useListVals<Team>(ref(db, `/hunt/${hunt_id}/teams`), {keyField: "id"});

  if (terror || error) {
    console.error(terror, error);
    return <h1>Error please refresh</h1>;
  }

  const tv = teams === undefined ? []
    :teams.flatMap(
      (t) => t.completedTasks === undefined
        ? []
        : Object.entries(t.completedTasks)
            .map<CTRef>(([ctid, ct]) => Object.assign({}, ct, {compltedTaskId: ctid, teamId: t.id})))
            // TODO readd needsVerification to it
            .filter((t) => t.needsVerification !== undefined);

  return (
    <>
      <Typography variant="h3">Questions</Typography>
      { loading || tloading || tasks === undefined
        ? <Typography>Loading...</Typography>
        : tv.map((task) => (
          <Card sx={{my: 2}} key={task.compltedTaskId} >
            <TaskCardContent
              task={tasks!.find((t) => t.id === task.task_id)! ?? "Could not find task"}
              submission={task}
              team={teams!.find((f) => f.id === task.teamId)! ?? "could not find team name"}
            />
            <CardActions>
              <Button
                color={task.needsVerification || task.needsVerification === undefined ? "primary" : "info"}
                onClick={() => update(ref(db, `/hunt/${hunt_id}/teams/${task.teamId}/completedTasks/${task.compltedTaskId}`),{needsVerification: false, correct: false})}
              >
                {task.needsVerification || task.needsVerification === undefined ? "Reject" : "Correct to reject"}
              </Button>
              <Button
                color={task.needsVerification || task.needsVerification === undefined ? "primary" : "info"}
                onClick={() => update(ref(db, `/hunt/${hunt_id}/teams/${task.teamId}/completedTasks/${task.compltedTaskId}`), {needsVerification: false, correct: true})}
              >
                {task.needsVerification || task.needsVerification === undefined ? "Accept" : "Correct to accept"}
              </Button>
            </CardActions>
          </Card>
        ))
      }
    </>
  );
}
