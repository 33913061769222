import { useState } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { QuestionDescription, QuestionTask } from '../lib/types';
import { CommonDetailsProps } from './Task';
import Hint from './Hint';

interface QuestionDetailsProps extends CommonDetailsProps {
  task: QuestionTask
}

export default function QuestionDetails({task, buyHint, completeTask, hintBought}: QuestionDetailsProps) {
  const [selectedAnswer, setAnswer] = useState<string|null>(null);

  if (typeof task.description === 'string')
    return (
      <AccordionDetails>
        <Typography>Ooops.. There was an Error</Typography>
      </AccordionDetails>
    );

  const question = task.description as QuestionDescription;

  return (
    <>
      <AccordionDetails>
        <FormControl component="fieldset">
          <FormLabel component="legend">{question.question}</FormLabel>
          <RadioGroup
            name="answer"
            value={selectedAnswer}
            onChange={(e) => setAnswer(e.target.value)}
          >
            {question.choices.map((c, idx) =>
              <FormControlLabel
                key={idx}
                control={<Radio />}
                label={c.text}
                value={c.text}
              />
            )}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
      <AccordionActions>
        <Hint
          bought={hintBought}
          buyHint={() => buyHint(task.id)}
          hint={task.hint}
        />
        <Button
          onClick={
            () => completeTask({
              task_id: task.id,
              correct: question.choices.find(
                (c) => c.text.toString() === selectedAnswer
              )?.correct === true
                ? true
                : false,
            })
          }
        >
          Submit
        </Button>
      </AccordionActions>
    </>
  );
}
