import { forwardRef, useMemo } from 'react';
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material';
import {
  Assessment as LeaderboardIcon,
  DirectionsRun as HuntIcon,
  Home as HomeIcon,
  List as TasksIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useHunt } from '../lib/HuntProvider';

interface MenuItemEntry {
  header?: boolean;
  href?: string;
  icon?: React.ReactElement;
  text: string;
}

interface MenuItemProps extends MenuItemEntry {
  onClick: () => void;
}

function MenuItem(props: MenuItemProps) {

  const renderLink = useMemo(() =>
    forwardRef<HTMLAnchorElement>((itemProps, ref) => {
      return (
        <Link
          to={props.href!}
          ref={ref}
          {...itemProps}
        />
      );
    }),
    [props],
  );

  return (
    <ListItemButton component={renderLink}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText>{props.text}</ListItemText>
    </ListItemButton>
  );
}

interface MenuProps {
  open: boolean;
  onClose: () => void;
}


const standardItems: (MenuItemEntry | "divider")[] = [
  { header: true, text: "Scavenger Hunt" },
  { href: "/", icon: <HomeIcon />, text: "Home" },
];


export default function Menu({open, onClose}: MenuProps) {
  const hunt = useHunt();

  const huntItems: (MenuItemEntry | "divider")[] = [
    "divider",
    { header: true, text: hunt.huntDetails?.name ?? "Hunt" },
    { href: "/hunt/leaderboard", icon: <LeaderboardIcon />, text: "Leaderboard" },
    { href: "/hunt/tasks", icon: <TasksIcon />, text: "Tasks" },
    { href: "/hunt/team", icon: <SettingsIcon />, text: "Team details" },
    { href: "/hunt/logout", icon: <LogoutIcon />, text: "Logout" },
  ];

  const items: (MenuItemEntry | "divider")[]  = hunt.huntId === undefined || hunt.teamId === undefined
    ? [...standardItems, { href: "/hunt", icon: <HuntIcon />, text: "Join a hunt" }]
    : [...standardItems, ...huntItems];

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List>
        { items.map(
        (item, idx) => item === 'divider'
          ? <Divider key={idx} />
          : item.header
            ? <ListSubheader key={idx} sx={{bgcolor: 'inherit'}}>{item.text}</ListSubheader>
            : <MenuItem key={idx} onClick={onClose} {...item} />
        )}
      </List>
    </Drawer>
  );
}
