import { useState } from 'react';
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useHunt } from '../lib/HuntProvider';
import Menu from './Menu';

interface LayoutProps {
  children: React.ReactChild | React.ReactChild[];
}

export default function Layout(props: LayoutProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { huntDetails, teamId } = useHunt();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setMenuOpen(true)}
            size="large">
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" sx={{flexGrow: 1}}>
            Scavenger Hunt
          </Typography>
          { teamId && huntDetails && (
            <Typography variant="caption">
              Team code: ({Object.entries(huntDetails.codes).reduce<string | undefined>((acc, [k,v]) => v === teamId ? k : acc, undefined)})
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      />
      <Container maxWidth="sm">
        {props.children}
      </Container>
    </>
  );
}
