import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { TaskView } from '../../components/Task';
import { useBuyHint, useCompleteTask, useTasks } from '../../lib/tasks';
import { useTeam } from '../../lib/teams';
import { useHuntAuthed } from '../../lib/HuntProvider';

export const Hunt : FC = () => {
  const { teamId } = useHuntAuthed();
  const [expandedId, setExpandedId] = useState<string | false>(false);
  const [tasks, tasks_loading, tasks_errors] = useTasks();
  const [team, team_loading, team_errors] = useTeam(teamId!)
  const completeTask = useCompleteTask();
  const buyHint = useBuyHint();

  if (tasks_loading || team_loading) return <h1>Loading...</h1>;
  if (tasks === undefined || team === undefined)
    return <h1>Error: something was undefined</h1>;
  if (tasks_errors)
    return <span>There was an error: {tasks_errors.name}</span>;
  if (team_errors)
    return <span>There was an error: {team_errors.name}</span>;

  const done_ids = team.completedTasks.map((c) => c.task_id)
  const todo = tasks.filter((t) => done_ids.indexOf(t.id) === -1);

  return (
    <main>
      <h1>{team.name}'s tasks</h1>
      {todo.length === 0
        ? <Typography>You're all out of tasks!</Typography>
        : todo.map((t) => (
          <TaskView
            key={t.id}
            task={t}
            buyHint={buyHint}
            hintBought={team.usedHints.indexOf(t.id) !== -1}
            completeTask={completeTask}
            expanded={t.id === expandedId}
            onClick={() => setExpandedId(t.id === expandedId ? false : t.id)}
            disabled={t.depends === undefined ? false : done_ids.indexOf(t.depends) === -1}
          />
        )
      )}
    </main>
  );
}

export default Hunt;
