import { createContext, ReactChild, useCallback, useContext, useEffect, useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import useLocalStorage from 'react-use-localstorage';
import { ref, push, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from './firebase';
import { HuntDetails } from './types';

export interface HuntContext {
  huntDetails?: HuntDetails;
  huntId?: string;
  invalidHuntId: boolean;
  huntLoading: boolean;
  teamId?: string;
  setHunt: (huntId: string) => void;
  createTeam: (teamName: string) => void;
  teamLogin: (teamCode: string) => boolean;
  logout: () => void;
}

const HUNT_CONTEXT = createContext<HuntContext>({
  invalidHuntId: false,
  huntLoading: false,
  createTeam: (team_name) => console.log(`Create a new team: ${team_name}`),
  teamLogin: (team_code) => {console.log(`Login a team: ${team_code}`); return true;},
  setHunt: (huntId) => console.log(`Hunt Id called with ${huntId}`),
  logout: () => null,
});

export function useHunt() {
  return useContext(HUNT_CONTEXT);
}

export function useHuntUnauthed() {
  const hunt =  useContext(HUNT_CONTEXT);
  const navigate = useNavigate();

  useEffect(() => {
    if (hunt.huntId && hunt.teamId) {
      navigate("/hunt/leaderboard");
    }
  }, [hunt, navigate]);

  return hunt;
}

export function useHuntAuthed() {
  const hunt =  useContext(HUNT_CONTEXT);
  const navigate = useNavigate();

  useEffect(() => {
    if (hunt.huntId === undefined || hunt.teamId === undefined) {
      navigate("/hunt");
    }
  }, [hunt, navigate]);

  return hunt;
}

function generateCode() {
  const numDigits = 4
  const code = (Math.floor(Math.random() * 10**numDigits)).toString();
  const toPad = numDigits - code.length;
  let res = "";
  for (let i = 0; i < toPad; i++) {
    res += "0";
  }
  return res + code;
}

function huntDetailsTF(val: any): HuntDetails | undefined{
  if (val === null || val === undefined) return undefined;
  return {
    name: val.name ?? "",
    codes: val.codes instanceof Array
      ? (val.codes as any[]).reduce<Record<string,string>>(
        (acc, v, idx) => Object.assign({}, acc, {[idx.toString()]: v}),
        {})
      : val.codes === undefined || val.codes === null
      ? {}
      : Object.assign({}, {}, val.codes),
  };
}

// const HuntProvider: FC = ({children}) => {
function HuntProvider({children}:{children: ReactChild | ReactChild[]}) {
  const { db } = useFirebase()!;
  const [huntId, setHuntId] = useLocalStorage("huntid", "");
  const [invalidHuntId, setInvalidHuntId] = useState(false);
  const [teamId, setTeamId] = useLocalStorage("teamid", "");
  const [huntDetails, huntLoading] = useObjectVal<HuntDetails | undefined>(
    ref(db, `/hunt/${huntId}/details`),
    {transform: huntDetailsTF}
  );

  const teamLogin = useCallback((id: string) => {
    if (huntDetails?.codes[id]) {
      setTeamId(huntDetails.codes[id]);
      return true;
    } else {
      return false;
    }
  }, [huntDetails, setTeamId]);

  const createTeam = useCallback((name: string) => {
    const newTeam = push(ref(db, `/hunt/${huntId}/teams`));

    let code = generateCode();
    while (huntDetails?.codes[code] ?? false) {
      code = generateCode();
    }

    set(ref(db, `/hunt/${huntId}/details/codes/${code}`), newTeam.key);
    set(newTeam, {
      name: name,
      code: code,
    });

    if (newTeam.key === null)
      console.error("new team key is null");
    else
      setTeamId(newTeam.key);
  }, [db, huntDetails, huntId, setTeamId]);

  useEffect(() => {
      setInvalidHuntId(huntId !== undefined
        && (huntDetails === undefined || huntDetails === null)
        && !huntLoading);
    },
    [setHuntId, huntLoading, huntDetails, huntId]
   );

  return (
    <HUNT_CONTEXT.Provider
      value={{
        teamId: teamId === "" ? undefined : teamId,
        huntId: huntId === "" ? undefined : huntId,
        huntLoading: huntLoading,
        invalidHuntId: invalidHuntId,
        setHunt: (id) => {
          setHuntId(id.toLowerCase());
          setInvalidHuntId(false);
        },
        createTeam: createTeam,
        teamLogin: teamLogin,
        huntDetails: huntDetails?? undefined,
        logout: () => {
          setTeamId("");
          setHuntId("");
        },
      }}
    >
      {children}
    </HUNT_CONTEXT.Provider>
  );
}
export default HuntProvider;
