import { useState } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Button,
  Typography,
} from '@mui/material';
import {
  PhotoCamera as PhotoIcon,
  Videocam as VideoIcon,
} from '@mui/icons-material';
import { CommonDetailsProps } from './Task';
import { BasicTask } from '../lib/types';
import Hint from './Hint';

interface CaptureDetailsProps extends CommonDetailsProps {
  task: BasicTask
}

export default function CaptureDetails({task, buyHint, completeTask, hintBought}: CaptureDetailsProps) {
  const [selectedFile, setSelectedFile] = useState<File>();

  return (
    <>
      <AccordionDetails>
        <Typography>{task.description}</Typography>
      </AccordionDetails>
      { selectedFile && task.type === "photo" && (
        <AccordionDetails>
          <img
            style={{
              display: "block",
              maxWidth: "100%",
              maxHeight: 250,
              margin: "0 auto",
            }}
            src={URL.createObjectURL(selectedFile)}
            alt=""
          />
        </AccordionDetails>
      )}
      { selectedFile && task.type === "video" && (
        <AccordionDetails>
          <video
            key={selectedFile.name}
            style={{
              display: "block",
              maxWidth: "100%",
              maxHeight: 250,
              margin: "0 auto",
            }}
            controls
          >
            <source
              src={URL.createObjectURL(selectedFile)}
              type={selectedFile.type}
            />
          </video>
        </AccordionDetails>
      )}
      <AccordionDetails style={{display: 'flex', justifyContent: 'center'}}>
        <input
          accept={task.type === 'photo' ? 'image/*' : 'video/*'}
          capture="environment"
          id={`file-upload-${task.id}`}
          style={{display: 'none'}}
          type="file"
          onChange={(e) => e.target.files === null
            ? setSelectedFile(undefined)
            : setSelectedFile(e.target.files[0])
          }
        />
        <label htmlFor={`file-upload-${task.id}`}>
          <Button
            color="primary"
            component="span"
            startIcon={task.type === 'photo' ? <PhotoIcon /> : <VideoIcon />}
            variant="contained"
          >
            {task.type === 'photo'
              ? "Take photo"
              : "Take video"
            }
          </Button>
        </label>
      </AccordionDetails>
      <AccordionActions>
        <Hint
          bought={hintBought}
          buyHint={() => buyHint(task.id)}
          hint={task.hint}
        />
        <Button
          disabled={selectedFile === undefined}
          onClick={() =>
            completeTask({
              task_id: task.id,
              correct: false,
              needsVerification: true,
              asset: selectedFile!,
            })
          }
        >
          Submit
        </Button>
      </AccordionActions>
    </>
  );
}
