import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Hint as HintType } from '../lib/types';

interface HintProps {
  hint?: HintType
  buyHint: () => void,
  bought: boolean,
}

interface BuyDialogueProps {
  cost: number;
  open: boolean;
  onClose: () => void;
  onBuy: () => void;
}

interface HintDialogueProps {
  text: string;
  open: boolean;
  onClose: () => void;
}

function BuyDialogue({cost, open, onClose, onBuy}: BuyDialogueProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Buy hint?</DialogTitle>
      <DialogContent>
        Are you sure you want to buy the hint?
        It will cost you {cost} points
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>No</Button>
        <Button color="primary" onClick={onBuy}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

function HintDialogue({text, open, onClose}: HintDialogueProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Hint</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Hint({ hint, bought, buyHint }: HintProps) {
  const [buyOpen, setBuyOpen] = useState(false);
  const [hintOpen, setHintOpen] = useState(false);

  if (hint === undefined)
    return <></>;

  return (
    <>
      {bought
        ? <Button onClick={() => setHintOpen(true)}>Hint</Button>
        : <Button onClick={() => setBuyOpen(true)}>Hint (-{hint.cost} pts)</Button>
      }
      <BuyDialogue
        cost={hint.cost}
        open={buyOpen}
        onClose={() => setBuyOpen(false)}
        onBuy={() => {
          setBuyOpen(false);
          buyHint();
          setHintOpen(true);
        }}
      />
      <HintDialogue
        text={hint.text}
        open={hintOpen}
        onClose={() => setHintOpen(false)}
      />
    </>
  );
}
