import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useTasks } from '../../lib/tasks';
import { useTeams } from '../../lib/teams';
import { useHuntAuthed } from '../../lib/HuntProvider';


interface TeamStat {
  name: string,
  total_tasks_completed: number,
  total_points: number,
}

export const Leaderboard : FC = () => {
  useHuntAuthed();
  const [tasks, tasks_loading, tasks_error] = useTasks();
  const [ts, teams_loading, teams_error] = useTeams();

  if (teams_loading || tasks_loading) return <h1>Loading</h1>;
  if (teams_error) return <span>Error: {teams_error.name}</span>;
  if (tasks_error) return <span>Error: {tasks_error.name}</span>;
  if (ts === undefined) return <span>Error: could not get teams</span>;
  if (tasks === undefined) return <span>Error: could not get tasks</span>;

  const teams = ts.map<TeamStat>((t) => ({
    name: t.name,
    total_tasks_completed: t.completedTasks.length,
    // TODO deal with race condition of two team members sending it at same time
    total_points: t.completedTasks.reduce<number>(
      (ans, completed_task) => {
        const task = tasks.find((task) => task.id === completed_task.task_id)
        if (task && completed_task.correct) {
          if (task.depends === undefined || t.completedTasks.find((ct) => ct.task_id === task.depends!)?.correct)
            return ans + task.points;
          return ans;
        }
        return ans;
      },
      0) - t.usedHints.map((id) => tasks.find((t) => t.id === id))
            .reduce<number>((acc, a) => a === undefined || a.hint === undefined ? acc : acc + a.hint.cost ?? 0, 0),
  }));

  return (
    <main>
      <h1>Leaderboard</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Team name</TableCell>
              <TableCell>Tasks completed</TableCell>
              <TableCell>Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { teams.sort((a, b) => b.total_points - a.total_points).map((t, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{t.name}</TableCell>
                <TableCell>{t.total_tasks_completed}</TableCell>
                <TableCell>{t.total_points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
}

export default Leaderboard;
