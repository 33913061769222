import { ref, push, set, update } from 'firebase/database';
import { ref as sref, uploadBytes } from "firebase/storage";
import { useListVals } from 'react-firebase-hooks/database';
import { useFirebase } from './firebase';
import { useHuntAuthed } from './HuntProvider';
import { Task, CompletedTask } from "./types";

export const useTasks = () => {
  const { db } = useFirebase()!;
  const hunt_id = useHuntAuthed().huntId;
  return useListVals<Task>(ref(db, `/hunt/${hunt_id}/task`), {
    keyField: "id",
  });
}

export const useCompleteTask = () => {
  const { db, storage } = useFirebase()!;
  const { huntId, teamId } = useHuntAuthed();

  return (task: CompletedTask) => {
    if (huntId === undefined || teamId === undefined) return;
    const set_at = push(ref(db, `/hunt/${huntId}/teams/${teamId}/completedTasks`));
    set(set_at, task);

    if (task.asset) {
      const storage_ref = sref(storage, `/hunt/${huntId}/${set_at.key}`);
      uploadBytes(storage_ref, task.asset).then(
        (snapshot) => {
          update(set_at, {asset_url: snapshot.ref.fullPath});
          alert("uplaoded")
        },
        (error) => {
          update(set_at, {uploadError: error});
          alert("Error uplaoding issue image");
        },
      );
    }
  }
}

export const useBuyHint = () => {
  const { db } = useFirebase()!;
  const { huntId, teamId } = useHuntAuthed();

  return (taskId: string) => {
    if (huntId === undefined || teamId === undefined) return;
    const set_at = push(ref(db, `/hunt/${huntId}/teams/${teamId}/usedHints`));
    set(set_at, taskId);
  }
}
