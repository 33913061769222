import { ref } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { useFirebase } from './firebase';
import { useHuntAuthed } from './HuntProvider';
import { Team } from "./types";

const transform_fn = (val: any): Team => {
  return {
    id: val["id"] || "",
    name: val["name"] || "",
    code: val["code"] || "",
    completedTasks: val.completedTasks instanceof Array
      ? val.completedTasks
      : val.completedTasks === undefined || val.completedTasks === null
      ? []
      : Object.values(val.completedTasks),
    usedHints: val.usedHints instanceof Array
      ? val.usedHints
      : val.usedHints === undefined || val.usedHints === null
      ? []
      : Object.values(val.usedHints),
  }
};

export const useTeam = (teamId: string) => {
  const { db } = useFirebase()!;
  const { huntId } = useHuntAuthed();

  return useObjectVal<Team>(ref(db, `/hunt/${huntId!}/teams/${teamId}`), {
    keyField: "id",
    transform: transform_fn
  });
}

export const useTeams = () => {
  const { db } = useFirebase()!;
  const { huntId } = useHuntAuthed();

  return useListVals<Team>(ref(db, `/hunt/${huntId!}/teams`), {
    keyField: "id",
    transform: transform_fn
  });
}
