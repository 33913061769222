import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 2,
      }}
    >
      <Typography variant="h4">Scavenger hunt</Typography>
      <Typography>Currently in closed beta</Typography>
      <Button
        component={Link}
        endIcon={<ChevronRight />}
        to="/hunt"
        sx={{mt: 20}}
        variant="contained"
      >
        Join a hunt
      </Button>
    </Box>
  )
}
