import { useState } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { CommonDetailsProps } from './Task';
import Hint from './Hint';
import {BasicTask} from '../lib/types';


interface TextQuestionDetailsProps extends CommonDetailsProps {
  task: BasicTask
}

export default function TextQestionTask({
  task,
  buyHint,
  completeTask,
  hintBought
}: TextQuestionDetailsProps) {
  const [inp, setInp] = useState("");
  return (
    <>
      <AccordionDetails>
        <Typography>{task.description}</Typography>
        <TextField
          label="Your answer"
          fullWidth
          value={inp}
          onChange={(e) => setInp(e.target.value)}
          sx={{mt: 2}}
        />
      </AccordionDetails>
      <AccordionActions>
        <Hint
          bought={hintBought}
          buyHint={() => buyHint(task.id)}
          hint={task.hint}
        />
        <Button
          disabled={inp === ""}
          onClick={() =>
            completeTask({
              task_id: task.id,
              correct: false,
              needsVerification: true,
              answer: inp,
            })
          }
        >
          Submit
        </Button>
      </AccordionActions>
    </>
  );
}
