import { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from 'react-router-dom';
import {
  createTheme,
  CssBaseline,
  useMediaQuery,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { FirebaseProvider } from './lib/firebase';
import HuntProvider, { useHunt } from './lib/HuntProvider';
import Error404 from './pages/404';
import HomePage from './pages/index';
import HuntHomePage from './pages/hunt/index';
import LeaderboardPage from './pages/hunt/leaderboard';
import TeamDetailsPage from './pages/hunt/details';
import TasksPage from './pages/hunt/tasks';
import OrgerniserPages from './pages/organiser';
import Layout from './components/Layout';

function HuntLogout() {
  const hunt = useHunt();

  useEffect(() => {
    hunt.logout();
  }, [hunt]);

  if (hunt.huntId || hunt.teamId)
    return <></>
  return <Navigate to="/" />
}

function HuntPages() {
  return (
    <Routes>
      <Route path="" element={<HuntHomePage />} />
      <Route path="leaderboard" element={<LeaderboardPage />} />
      <Route path="tasks" element={<TasksPage />} />
      <Route path="team" element={<TeamDetailsPage />} />
      <Route path="logout" element={<HuntLogout />} />
    </Routes>
  );
}

function Pages() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="hunt/*" element={<HuntPages />} />
          <Route path="god/*" element={<OrgerniserPages />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Error404 />}/>
        </Routes>
      </Layout>
    </Router>
  );
};

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: '#00ffa4',
          dark: '#00cc83',
          light: '#32ffb6',
        },
        secondary: {
          main: '#ff0059',
          dark: '#cc0047',
          light: '#ff327a',
        },
      }
    }),
    [prefersDarkMode]
  );

  return (
    <FirebaseProvider>
      <HuntProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Pages />
          </ThemeProvider>
        </StyledEngineProvider>
      </HuntProvider>
    </FirebaseProvider>
  );
}

export default App;
