import {
  Accordion,
  AccordionSummary,
  Box,
  Chip,
  Icon,
  Typography,
} from '@mui/material';
import {
  Create as TextQuestionIcon,
  ExpandMore as ExpandMoreIcon,
  LocationOn as LocationIcon,
  QuestionAnswer as QuestionIcon,
  PhotoCamera as PhotoIcon,
  Videocam as VideoIcon,
} from '@mui/icons-material';
import { CompletedTask, Task, TaskType } from '../lib/types';
import { VisitDetails } from './VisitTask';
import QuestionDetails from './QuestionTask';
import CaptureDetails from './CaptureTask';
import TextQuestionTask from './TextQuestionTask';

interface TaskViewProps {
  buyHint: (taskId: string) => void;
  completeTask: (task: CompletedTask) => void;
  hintBought: boolean;
  task: Task;
  expanded: boolean;
  disabled: boolean;
  onClick: () => void;
}

export interface CommonDetailsProps {
  buyHint: (taskId: string) => void;
  completeTask: (task: CompletedTask) => void;
  hintBought: boolean;
}

const taskIcon: Record<TaskType, JSX.Element> = {
  'text': <TextQuestionIcon />,
  'visit': <LocationIcon />,
  'question': <QuestionIcon />,
  'photo': <PhotoIcon />,
  'video': <VideoIcon />,
}

export function TaskView({task, expanded, onClick, disabled, ...commonProps}: TaskViewProps) {

  return (
    <Accordion expanded={expanded} onChange={onClick} disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Icon>{taskIcon[task.type]}</Icon>
        <Box ml={1}>
          <Typography>
            {task.title}<span style={{color: "#909090"}}> ({task.points}pts)</span>
          </Typography>
        </Box>
        { task.label && (
          <Chip
            size="small"
            style={{margin: "0 8px 0 auto"}}
            label={task.label}
            variant="outlined"
          />
        )}
      </AccordionSummary>
      {task.type === 'visit'
        ? <VisitDetails task={task} {...commonProps} />
        : task.type === 'question'
        ? <QuestionDetails task={task} {...commonProps} />
        : task.type === 'photo' || task.type === 'video'
        ? <CaptureDetails task={task} {...commonProps} />
        : task.type === 'text'
        ? <TextQuestionTask task={task} {...commonProps} />
        : <Typography>Ooops... something went wrong</Typography>
      }
    </Accordion>
  );
}
